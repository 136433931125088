export const constants = {
  appName: "C.A.P.A.L.",
  logo: "https://mylogo.com.ar/img.svg",
  logoBanner: "https://mylogo.com.ar/img.svg",
  banner: "",
  backgroundImg: "",
  history:
    "Fundada el 20 de noviembre de 1978. C.A.P.A.L, es la Cooperativa de Agua Potable y otros servicios de Alcorta Ltda. y tambien presta el Servicio solidario de Sepelio para sus asociados.",
  email: "capalalcorta@gmail.com",
  phone: "+54 03465-470038",
  linkText: "Para ver tus facturas ingresa Aqui",
  link: "https://mylink.com.ar",
  address: "Sarmiento 151. Alcorta",
  whatsapp: "+54 03465-655041",
  businessHours: "Lunes a Viernes de 10hs a 13hs",
  custom:
    "Lorem ipsum dolor sit amet consectetur adipiscing elit fringilla tincidunt.",
  custom2:
    "Lorem ipsum dolor sit amet consectetur adipiscing elit fringilla tincidunt.",
};
