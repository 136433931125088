import React from "react";
import { NavbarComponent } from "../../components/NavbarComponent";
import { constants } from "../../constants";
import "./HomePage.css";
import logoBanner from "../../../src/logo_new.png";

export const HomePage: React.FC = () => {
  return (
    <>
      <div className="backgroundImg">
        <div className="container-fluid">
          <NavbarComponent />
          <div className="row text-center text-white">
            <div className="col-md-6 mx-auto textColor mt-5 mb-5">
              <img className="img-fluid logoBanner" src={logoBanner} alt="" />
              <p>{constants.history}</p>
              OFICINA VIRTUAL –{" "}
              <a href="https://alcorta.stage.com.ar/" target="_blank">
                {" "}
                <u style={{ color: "yellow" }}>
                  <b>INGRESE AQUÍ</b>
                </u>
              </a>
              {/* <br />
              <br />
              <h5>Contacto</h5>
              <span>{constants.address}</span> */}
              {/* <br /> */}
              {/* <br /> */}
              {/* <span>HORARIO DE ATENCION:</span> */}
              {/* <br /> */}
              {/* <span>{constants.businessHours}</span> */}
              {/* <br />
              <span>TELEFONO: {constants.phone}</span>
              <br />
              <span>REPARACIONES: {constants.whatsapp}</span>
              <br />
              <span>EMAIL: {constants.email}</span> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
