import React from "react";
import { NavbarComponent } from "../../components/NavbarComponent";
import { constants } from "../../constants";
import logoBanner from "../../../src/logo_new.png";
import "./ContactPage.css";

export const ContactPage: React.FC = () => {
  return (
    <>
      <div className="backgroundImg">
        <div className="container-fluid">
          <NavbarComponent />
          <div className="row text-center text-white">
            <div className="col-md-6 mx-auto textColor mb-5">
              <img className="img-fluid logoBanner" src={logoBanner} alt="" />
              <h1>Contacto</h1>
              <span>{constants.address}</span>
              {/* <br /> */}
              {/* <span>HORARIO DE ATENCION:</span> */}
              {/* <br /> */}
              {/* <span>{constants.businessHours}</span> */}
              <br />
              <span>TELEFONO: {constants.phone}</span>
              <br />
              <span>REPARACIONES: {constants.whatsapp}</span>
              <br />
              <span>EMAIL: {constants.email}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
