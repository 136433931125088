import React from "react";
import "./NavbarComponent.css";
import { constants } from "../constants";
import { Link } from "react-router-dom";
import logoBanner from "../../src/logo_new.png";

export const NavbarComponent: React.FC = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <a className="navbar-brand" href="#">
          <img src={logoBanner} width="80" alt="" />
        </a>
        <a className="navbar-brand text-white" href="#">
          {constants.appName}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <Link className="nav-link" to="/">
                Inicio <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                Contacto
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};
